@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.dao-page {
  font-family: 'Poppins', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 3rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

section {
  background: white;
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
  transition: transform 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

section:hover {
  transform: translateY(-5px);
}

h2 {
  color: #3498db;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

p, ul, ol {
  color: #34495e;
  line-height: 1.6;
}

ul, ol {
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}

.join-button {
  display: block;
  width: 200px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(46, 204, 113, 0.3);
}

.join-button:hover {
  background-color: #27ae60;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(46, 204, 113, 0.4);
}

@media (max-width: 768px) {
  .dao-page {
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  section {
    padding: 1.5rem;
  }
}

/* Animated background for token utility section */
.token-utility {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  color: white;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Interactive hover effect for governance process */
.governance-process ol {
  counter-reset: governance-steps;
}

.governance-process li {
  list-style-type: none;
  position: relative;
  padding-left: 40px;
  margin-bottom: 1rem;
  counter-increment: governance-steps;
}

.governance-process li::before {
  content: counter(governance-steps);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #3498db;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: all 0.3s ease;
}

.governance-process li:hover::before {
  background-color: #2980b9;
  transform: translateY(-50%) scale(1.1);
}

/* NFT gallery effect */
.nft-integration ul {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.nft-integration li {
  background-color: #f1c40f;
  color: #34495e;
  padding: 1rem;
  border-radius: 10px;
  margin: 0.5rem;
  flex-basis: calc(33.333% - 1rem);
  text-align: center;
  transition: all 0.3s ease;
}

.nft-integration li:hover {
  transform: rotate(5deg);
  box-shadow: 0 5px 15px rgba(241, 196, 15, 0.4);
}

/* DeFi exchange stats animation */
.defi-exchange ul {
  display: flex;
  justify-content: space-around;
}

.defi-exchange li {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
  position: relative;
}

.defi-exchange li::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #3498db;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.defi-exchange li:hover::after {
  transform: scaleX(1);
}

.join-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: var(--clr-blue);
  color: white;
  transition: all 0.3s ease;
}

.join-button.locked {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.lock-icon {
  margin-right: 10px;
}
