@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

/* ------------------------------
   1. Root Variables
------------------------------- */
:root {
  --clr-primary: #2c2c2c;
  --clr-secondary: #004C54;
  --clr-neutral: #FFFFFF;
  --clr-white: #FFFFFF;
  --clr-blue: #FF971F;
  --clr-red: #F45353;
  --clr-green: #25CE8F;
  --clr-black: #1A1A1A;
  --clr-gradient-primary: linear-gradient(135deg, #004C54 0%, #007A78 100%);
  --clr-gradient-secondary: linear-gradient(135deg, #2c2c2c 0%, #4c4c4c 100%);
  --clr-gradient-neutral: linear-gradient(135deg, #FFFFFF 0%, #F0F0F0 100%);
  /* If you had a var(--clr-text) reference, define it or replace it with var(--clr-white) */
  /* --clr-text: #FFFFFF; */
}

/* ------------------------------
   2. Global Resets
------------------------------- */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: "DM Sans", sans-serif;
  color: var(--clr-white);
  scroll-behavior: smooth;
}

main {
  /* Because the navbar is fixed, we pad top so content is visible below it. */
  padding-top: calc(10vh + 20px); /* or 60px; match the navbar height if pinned */
  min-height: 100vh;
}

/* ------------------------------
   3. Common Text & Layout Helpers
------------------------------- */
h1 {
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 500;
  background: var(--clr-gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Merriweather', serif;
}

h2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0.375em 0;
  font-weight: 500;
  font-size: clamp(0.95rem, 2vw, 1.10rem);
}

p,
small {
  font-weight: 400;
}
small {
  color: var(--clr-neutral);
}

/* Flex & Grid Utilities */
.flex {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  margin: 0 auto;
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 500;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-1 {
  flex: 1;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

/* ------------------------------
   4. Exchange Section Layout
------------------------------- */
.exchange-heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--clr-blue);
  margin: 2rem 0;
  padding: 1rem;
  background: linear-gradient(45deg, var(--clr-primary), var(--clr-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.exchange__section--left {
  background: var(--clr-secondary);
  padding: 2em;

  /* Grid Setup */
  grid-column-start: 1;
  grid-column-end: 13;
}

.exchange__section--right {
  background: var(--clr-primary);

  padding: 0.25em 0.75em 0;

  /* Grid Setup */
  grid-column-start: 1;
  grid-column-end: 13;
}

/* ------------------------------
   5. Navbar / Header
------------------------------- */
/* 
   Pinned Nav:
   - position: fixed at top
   - scrolled effect changes background color
*/

.exchange__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  background: var(--clr-primary);

  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  padding: 5px 0;
}

.exchange__header--top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.exchange__header--bottom {
  background: var(--clr-secondary);
  padding: 10px 0;
}

/* On scroll, add transparency & box-shadow (as an example) */
.exchange__header.scrolled {
  background-color: rgba(44, 44, 44, 0.7);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.exchange__header--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Shared header elements */
.exchange__header small {
  margin: 0 0.5em 0 0;
}

.exchange__header select,
.exchange__chart select {
  background: transparent;
  color: var(--clr-white);
  border: none;
  font-weight: 700;
  cursor: pointer;
}
.exchange__header select:focus {
  background: var(--clr-secondary);
}

/* Child containers: brand, networks, nav, account */
.exchange__header--brand,
.exchange__header--networks,
.exchange__header--nav,
.exchange__header--account {
  display: flex;
  align-items: center;
}

.exchange__header--brand {
  flex: 0 0 auto;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.exchange__header--brand h1 {
  font-size: 1rem;
  margin-left: 10px;
  background: linear-gradient(45deg, var(--clr-blue), var(--clr-green));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

/* NETWORK SELECTION */
.exchange__header--networks {
  margin-right: 20px;
}
.exchange__header--networks select:focus {
  background: var(--clr-secondary);
}

/* NAV LINKS (Hub, Trade, Govern, etc.) */
.exchange__header--nav {
  flex: 1;
  justify-content: center;
}
.exchange__header--nav a {
  margin: 0 10px;
  font-size: 0.9rem;
  text-decoration: none;
  color: var(--clr-white);
  transition: color 0.2s ease;
}
.exchange__header--nav a:hover {
  background-color: var(--clr-blue);
}

/* ACCOUNT SECTION (Balance, Connect/Disconnect) */
.exchange__header--account {
  background: var(--clr-secondary);
  flex: 0 1 auto;
  margin-left: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  height: 40px; /* Reduce height */
}
.exchange__header--account p {
  margin: 0 auto;
}
.exchange__header--account small {
  color: var(--clr-white);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.5em 0.75em;
  border-radius: 0.5em;
  margin-right: 0.5em;
}
.exchange__header--account a,
.exchange__header--account button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222D41;
  border: none;
  color: var(--clr-white);
  margin: 0;
  border-radius: 10px;
  width: 182px;
  height: 48px;
  font-size: 16px;
  text-decoration: none;
  transition: all 250ms ease;
  cursor: pointer;
}
.exchange__header--account button:hover {
  background: var(--clr-blue);
}
.exchange__header--account img {
  width: 32px;
  height: auto;
  margin: 0 0 0 0.75em;
}

.account-actions {
  display: flex;
  align-items: center;
}
.account-actions a,
.account-actions button {
  margin-left: 0.5em;
}

/* Fancy nav-link classes, if needed for custom animations */
.nav-link {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: var(--clr-white);
  text-decoration: none;
  transition: color 0.3s ease;
}
.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--clr-blue);
  transition: width 0.3s ease;
}
.nav-link:hover::after {
  width: 100%;
}
.nav-link:hover {
  background: linear-gradient(90deg, var(--clr-blue) 0%, var(--clr-green) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Disconnect button style */
.wallet-disconnect {
  background-color: var(--clr-red);
  color: var(--clr-white);
  border: none;
  border-radius: 0.5em;
  padding: 0.5em 0.75em;
  cursor: pointer;
  transition: 0.3s ease;
}
.wallet-disconnect:hover {
  background-color: #e74c3c;
}

/* ------------------------------
   6. COMPONENT UTILS
------------------------------- */
.component {
  position: relative;
}
.component__header {
  margin: 0 0 0.75em 0;
}

/* ------------------------------
   7. MARKET/EXCHANGE COMPONENTS
------------------------------- */
.exchange__markets,
.exchange__transfers,
.exchange__orders {
  grid-column-start: 2;
  grid-column-end: 12;
}
.exchange__markets select {
  background: var(--clr-primary);
  color: var(--clr-white); /* replaced var(--clr-text) with var(--clr-white) */
  border: none;
  width: 100%;
  padding: 1em;
  font-weight: 700;
  cursor: pointer;
  appearance: none;
  position: relative;
}


.exchange__transfers img {
  float: left;
  margin: 0 0.3em 0 0;
}
.exchange__transfers--form .flex-between {
  padding: 0 3em 0.75em 0;
}

.exchange__transfers--form p {
  color: #0047AB;
  font-weight: bold;
}

.exchange__transfers--form p small {
  color: #555; /* Slightly lighter color for small text */
}

.exchange__transfers--form p:nth-child(2),
.exchange__transfers--form p:nth-child(3) {
  color: #FF971F;
  font-weight: bold;
}


/* Chart */
.exchange__chart {
  background: var(--clr-secondary);

  padding: 0.75em 1.75em;
  margin: 0.75em;
  min-height: 325px;

  grid-column-start: 1;
  grid-column-end: 13;
}
.apexcharts-canvas {
  margin: 0 auto;
}
.apexcharts-tooltip {
  background: var(--clr-primary);
  color: var(--clr-white);
}

/* Transactions */
.exchange__transactions {
  background: var(--clr-secondary);

  padding: 0.75em 1.75em;
  margin: 0.75em;

  grid-column-start: 1;
  grid-column-end: 13;

  height: 215px;
  overflow-y: scroll;
  scrollbar-width: thin;
}
.exchange__transactions::-webkit-scrollbar,
.exchange__trades::-webkit-scrollbar {
  background: var(--clr-blue);
  width: 4px;
}
.exchange__transactions::-webkit-scrollbar-thumb,
.exchange__trades::-webkit-scrollbar-thumb {
  background: var(--clr-white);
  border-radius: 15px;
}

/* Trades */
.exchange__trades {
  background: var(--clr-secondary);

  padding: 0.75em 1.75em;
  margin: 0.75em;

  grid-column-start: 1;
  grid-column-end: 13;

  height: 215px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

/* Orderbook */
.exchange__orderbook {
  background: var(--clr-secondary);
  padding: 0.75em 1.75em;
  margin: 0.75em;
  grid-column-start: 1;
  grid-column-end: 13;
}
.exchange__orderbook--sell tbody tr:hover,
.exchange__orderbook--buy tbody tr:hover {
  background: var(--clr-primary);
  cursor: pointer;
}

/* Images */
.logo {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}
.logo:hover {
  transform: scale(1.1);
}
.identicon {
  margin: 0 0 0 0.6em;
}

/* ------------------------------
   8. Additional Layout & Misc
------------------------------- */
#exchange,
#dao {
  padding-top: 80px; /* Adjust if pinned nav is a different height */
  margin-top: -80px; /* Offsets the anchor so it aligns nicely */
}

hr {
  background: #2E3950;
  width: 100%;
  height: 1px;
  margin: 1.75em auto;
  border: none;
}
.hr--vertical {
  flex: 1;
  background: #2E3950;
  margin: 0 auto;
  width: 1px;
  height: 100%;
}
.divider {
  padding: 0 15px;
}
.content-divider {
  height: 4px;
  background: linear-gradient(to right, var(--clr-blue), var(--clr-green));
  margin-bottom: 20px;
}
.content-background {
  background-image: url('data:image/svg+xml,%3Csvg width="20" height="20" ... etc');
  padding: 20px 0;
}

.balance-amount {
  color: var(--clr-blue);
  font-weight: bold;
}



/* ------------------------------
   9. Buttons
------------------------------- */
.button,
.button--sm {
  background: transparent;
  color: var(--clr-blue);
  border: 1.5px solid var(--clr-blue);
  border-radius: 10px;
  width: 100%;
  margin: 0.75em auto;
  padding: 1.15em;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  transition: all 250ms ease;
}
.button--sm {
  width: 50%;
  margin: 0 auto;
  padding: 0.25em;
  border-radius: 5px;
}
.button span {
  position: relative;
}
.button span::after {
  content: '';
  background-image: url('./assets/right-arrow-blue.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translate(50%, -50%);
  width: 10px;
  height: 12px;
  transition: all 250ms ease;
}
.button:hover,
.button--sm:hover {
  color: var(--clr-white);
  border: 1.5px solid var(--clr-white);
}
.button:hover span::after {
  background-image: url('./assets/right-arrow-white.svg');
  right: -18px;
}
.button--filled {
  background: var(--clr-blue);
  color: var(--clr-white);
  border: 1px solid transparent;
}
.button--filled span::after {
  background-image: url('./assets/right-arrow-white.svg');
}
.button--filled:hover {
  border: 1px solid transparent;
}

/* ------------------------------
   10. Inputs
------------------------------- */
input {
  background: var(--clr-primary);
  color: var(--clr-white); /* or var(--clr-text) if you define it */
  border: none;
  border-radius: 10px;
  width: 100%;
  margin: 0.75em auto;
  padding: 1.15em;
  font-family: "DM Sans";
  font-weight: 400;
  outline: none;
  position: relative;
}
input::placeholder {
  color: var(--clr-neutral);
}

/* ------------------------------
   11. Tabs
------------------------------- */
.tabs {
  background: var(--clr-primary);
  border-radius: 10px;
  padding: 0.20em;
}
.tab {
  background: transparent;
  color: var(--clr-white);
  min-width: 6em;
  padding: 0.50em 0.75em;
  border: none;
  border-radius: 8px;
  font-family: "DM Sans";
  font-weight: 500;
  cursor: pointer;
  position: relative;
  /* transition: all 250ms ease; */
}
.tab--active {
  background: var(--clr-blue);
}

/* ------------------------------
   12. Table
------------------------------- */
table {
  table-layout: fixed;
  width: 100%;
}
table caption {
  text-align: left;
  margin: 0 0 0.50em;
}
table th {
  color: var(--clr-neutral);
  margin: 0.50em 0;
  font-size: 0.85em;
  font-weight: 500;
  text-align: right;
}
table th img {
  float: right;
}
table th:first-child,
td:first-child {
  text-align: left;
  display: flex;
}
table td {
  min-width: max-content;
  margin: 0.25em 0;
  font-size: clamp(0.90rem, 2vw, 0.95rem);
  font-weight: 400;
  text-align: right;
}

/* ------------------------------
   13. Alerts
------------------------------- */
.alert {
  background: #222D41;
  height: 100px;
  width: 300px;
  position: fixed;
  bottom: 1.5em;
  right: 1.5em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 500ms ease;
}
.alert a {
  color: var(--clr-blue);
  text-decoration: none;
  margin: 0.25em;
}
.alert a:hover {
  color: var(--clr-white);
}
.alert--remove {
  opacity: 0;
  pointer-events: none;
}

/* ------------------------------
   14. Banner
------------------------------- */
.banner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

/* ------------------------------
   15. Media Queries
------------------------------- */

@media screen and (min-width: 576px) {

  .exchange__markets,
  .exchange__transfers,
  .exchange__orders {
    grid-column-start: 3;
    grid-column-end: 11;
  }
}

@media screen and (min-width: 768px) {

  .exchange__header--top {
    flex-wrap: wrap;
  }

  .exchange__header--brand,
  .exchange__header--networks,
  .exchange__header--nav,
  .exchange__header--account {
    flex-basis: 100%;
    margin-bottom: 5px;
    justify-content: center;
  }

  .exchange__header--container {
    flex-wrap: wrap;
  }

  .exchange__header--brand,
  .exchange__header--networks,
  .exchange__header--account {
    flex-basis: 100%;
    margin-bottom: 10px;
  }

  .exchange__header--nav a {
    font-size: 0.8em;
    padding: 3px 8px;
  }

  main {
    padding-top: calc(15vh + 20px); /* Increase for smaller screens if navbar gets taller */
  }

  .exchange__markets {
    grid-column-start: 1;
    grid-column-end: 6;

    grid-row-start: 1;
    grid-row-end: 3;
  }

  .exchange__orders {
    grid-column-start: 1;
    grid-column-end: 6;

    grid-row-start: 2;
    grid-row-end: 3;
  }

  .exchange__transfers {
    grid-column-start: 7;
    grid-column-end: 13;

    grid-row-start: 1;
    grid-row-end: 3;
  }

  .exchange__transactions {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .exchange__trades {
    grid-column-start: 7;
    grid-column-end: 13;
  }
}

@media screen and (min-width: 992px) {
  /* If you have .exchange__header inside .grid, place brand/networks with grid columns */
  
  .exchange__header--brand {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .exchange__header--networks {
    grid-column-start: 4;
    grid-column-end: 6;
  }

   .exchange__markets {
    grid-column-start: 2;
    grid-column-end: 6;

    grid-row-start: 1;
    grid-row-end: 3;
  }

  .exchange__orders {
    grid-column-start: 2;
    grid-column-end: 6;

    grid-row-start: 2;
    grid-row-end: 3;
  }

  .exchange__transfers {
    grid-column-start: 7;
    grid-column-end: 12;

    grid-row-start: 1;
    grid-row-end: 3;
  }
  
}

@media screen and (max-width: 768px) {
  .exchange__header--top {
    flex-wrap: wrap;
  }

  .exchange__header--brand,
  .exchange__header--networks,
  .exchange__header--account {
    flex-basis: 100%;
    margin-bottom: 10px;
  }

  .exchange__header--nav a {
    font-size: 0.9em;
    padding: 5px 10px;
  }
}

@media screen and (min-width: 1200px) {
  .exchange__header {
    padding: 0.5em 2em;
  }
  .exchange__header--nav a {
    padding: 0.5em 1em;
    margin: 0 0.25em;
  }

  .exchange__section--left {
    padding: 0.25em 2em 0;

    grid-column-start: 1;
    grid-column-end: 5;
  }

  .exchange__section--right {
    grid-column-start: 5;
    grid-column-end: 13;
  }

  .exchange__markets,
  .exchange__transfers,
  .exchange__orders {
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .exchange__markets {
    grid-row: 1;
  }

  .exchange__transfers {
    grid-row: 2;
  }

  .exchange__orders {
    grid-row: 3;
  }
}

@media screen and (min-width: 1600px) {
  .exchange__section--left {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .exchange__section--right {
    grid-column-start: 4;
    grid-column-end: 13;
  }

  .exchange__header--brand {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .exchange__header--networks {
    grid-column-start: 4;
    grid-column-end: 5;
  }
}

@media screen and (min-width: 1600px) {
  .exchange__section--left {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .exchange__section--right {
    grid-column-start: 4;
    grid-column-end: 13;
  }

}
