.circular-feature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.circular-feature-container:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.circular-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.circular-feature-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.circular-feature-title {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-bottom: 5px;
}

.circular-feature-description {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
}

.circular-feature-button {
  background-color: var(--primary-color);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  text-decoration: none;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
}

.circular-feature-button:hover {
  background-color: var(--accent-color);
}

.circular-locked-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locked {
  opacity: 0.7;
  pointer-events: none;
}
