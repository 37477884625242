.exchange-header {
  background: linear-gradient(135deg, var(--clr-primary) 0%, var(--clr-secondary) 100%);
  padding: 3rem 1rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.exchange-header-content {
  position: relative;
  z-index: 2;
}

.exchange-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.title-word {
  display: inline-block;
  background: linear-gradient(45deg, var(--clr-blue), var(--clr-green));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: float 6s ease-in-out infinite;
}

.title-word:nth-child(2) {
  animation-delay: 0.2s;
}

.title-word:nth-child(3) {
  animation-delay: 0.4s;
}

.exchange-subtitle {
  font-size: 1.2rem;
  color: var(--clr-white);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}

.exchange-decoration {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.decoration-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--clr-blue);
  animation: pulse 2s infinite;
}

.decoration-line {
  width: 100px;
  height: 2px;
  background-color: var(--clr-blue);
  animation: expand 2s infinite;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.5); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes expand {
  0% { transform: scaleX(1); }
  50% { transform: scaleX(1.2); }
  100% { transform: scaleX(1); }
}

@media (max-width: 768px) {
  .exchange-title {
    font-size: 2rem;
  }

  .exchange-subtitle {
    font-size: 1rem;
  }
}
