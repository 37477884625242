@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Playfair+Display:wght@700&display=swap');

:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --accent-color: #e74c3c;
  --text-color: #333;
  --bg-color: #f9f9f9;
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Poppins', sans-serif;
}

.hero-content {
  text-align: center;
  max-width: 800px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 4rem 2rem;
}

.hero-title {
  font-family: 'Playfair Display', serif;
  font-size: 3.5rem;
  font-weight: 700;
  color: #1a1a1a;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 1rem;
  animation: fadeInUp 0.6s ease-out forwards;
}

.hero-svg {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #333;
  max-width: 600px;
  margin: 0 auto 2rem;
  animation: fadeInUp 0.6s ease-out 0.2s forwards;
}

.buy-and-addresses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

.buy-now-button {
  background-color: #e67e22; /* or var(--accent-color) */
  border: none;
  border-radius: 30px;
  color: #fff;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
}

.buy-now-button:hover {
  background-color: #d35400;
  transform: translateY(-2px);
}

.hero-contract-links {
  text-align: left;
  background-color: #fdfdfd;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.08);
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

.hero-contract-links p {
  margin: 0.5rem 0;
}

.hero-contract-links a {
  color: #2ecc71;
  font-weight: 600;
  text-decoration: none;
}

.hero-contract-links a:hover {
  text-decoration: underline;
}

/* Container that holds uniswap-embed and dexscreener-embed side by side */
.swap-and-chart {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 1.5rem auto;
  width: 100%;
  max-width: 950px; /* Adjust if you want them narrower or wider */
}

.uniswap-embed, .dexscreener-embed {
  flex: 1 1 400px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  border: 2px solid #eee;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.uniswap-embed h3, .dexscreener-embed h3 {
  font-size: 1.2rem;
  color: #444;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

/* iFrames adapt to container width while retaining height */
.uniswap-embed iframe, .dexscreener-embed iframe {
  border: none;
  width: 100%;
}


.cta-container {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  animation: fadeInUp 0.6s ease-out 0.4s forwards;
}

.cta-button {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-decoration: none;
  color: #fff;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.cta-button:first-child {
  background-color: var(--primary-color);
  color: white;
}

.cta-button:last-child {
  background-color: var(--secondary-color);
  color: white;
}

.opensea-link:hover {
  background-color: var(--accent-color);
}

.ipfs-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

/* Locked Button Styles */
.cta-button.locked {
  background-color: #bdc3c7; /* Light grey background */
  color: var(--lock-color);
  cursor: not-allowed;
  pointer-events: none; /* Disable clicking */
  opacity: 0.7; /* Greyed-out look */
  position: relative;
}

.cta-button.locked .lock-icon {
  margin-right: 8px; /* Space between lock icon and text */
  fill: var(--lock-color); /* Ensure the lock icon inherits the grey color */
}

.lock-icon {
  width: 20px;
  height: 20px;
  fill: var(--lock-color);
}

.cta-button.locked::after {
  content: "Coming Soon";
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--lock-color);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  opacity: 0.8;
  pointer-events: none;
}

.section-title {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.ipfs-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.ipfs-image:hover {
  transform: scale(1.05);
}

.placeholder-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in-out {
  animation: fadeInOut 2.5s infinite;
}

.uniswap-embed {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px; /* Adjust if you want a narrower swap area */
  margin: 1rem auto;
  gap: 0.5rem;
  background-color: #fafafa;
  border: 2px solid #eee;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.uniswap-embed h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #444;
  margin-bottom: 0.5rem;
}


@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

.ipfs-description {
  margin-top: 10px;
  font-size: 1rem;
  color: #555;
}

.event-highlights {
  width: 100%;
  max-width: 600px;
}

.event-list {
  list-style-type: none;
  padding: 0;
}

.event-list li {
  margin-bottom: 10px;
  font-size: 1rem;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 40px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    padding: 20px 10px;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1rem;
  }

  .cta-container {
    flex-direction: column;
    width: 100%;
  }

  .cta-button {
    width: 100%;
  }

  .ipfs-section {
    max-width: 100%;
  }

  .features-container {
    flex-direction: column;
    align-items: center;
  }
  .swap-and-chart {
    flex-direction: column;
    align-items: center;
  }

  .uniswap-embed, .dexscreener-embed {
    max-width: 95%;
  }

  .hero-contract-links {
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
  }

  .hero-description {
    font-size: 0.9rem;
  }

  .buy-now-button {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }

  .hero-contract-links {
    font-size: 0.8rem;
    padding: 0.5rem 0.75rem;
  }
}
