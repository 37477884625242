.footer {
  background: linear-gradient(to bottom, #2c2c2c, #1a1a1a);
  color: #fff;
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.footer-section h2 {
  font-size: 1.8em;
  margin-bottom: 15px;
  letter-spacing: 1px;
  position: relative;
}

.footer-section h2::after {
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  background-color: #FF971F;
  margin-top: 10px;
}

.footer-section p {
  font-size: 1.1em;
  line-height: 1.6;
}

.social-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-icon {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 1.1em;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
}

.social-icon svg {
  width: 28px;
  height: 28px;
  margin-right: 15px;
  fill: currentColor;
  transition: transform 0.3s ease;
}

.social-icon:hover {
  color: #FF971F;
}

.social-icon:hover svg {
  transform: scale(1.1);
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 20px;
  margin-top: 30px;
  font-size: 0.9em;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
  }

  .footer-section, .social-icons {
    text-align: center;
    align-items: center;
  }
}
