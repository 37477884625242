/*******************************************
  NAVBAR LAYOUT
********************************************/

/* The fixed navbar */
.navbar-row {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: var(--clr-primary);
  transition: background-color 0.5s ease, box-shadow 0.3s ease;
  padding: 10px 20px;
}

.navbar-row.scrolled {
  background: rgba(var(--clr-primary-rgb), 0.8);
  backdrop-filter: blur(6px);
  box-shadow: 0 2px 10px rgba(0,0,0,0.15);
}

/* Container dividing left & right sections */
.navbar-content {
  display: flex;
  justify-content: space-between; /* left side, right side */
  align-items: center;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap; /* So it reorganizes on narrow screens */
  gap: 1rem;
}

.navbar-tabs {
  background-color: var(--clr-secondary);
  border-radius: 10px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

/* Left side for circular nav items */
.navbar-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Right side for account, balances, etc. */
.navbar-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.circular-navbar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

/*******************************************
  SEARCH ROW BELOW NAV
********************************************/
.search-row {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--clr-primary);
  padding: 10px 0;
  margin-top: 80px; /* or enough to clear the fixed navbar */
  position: relative;
  z-index: 998; /* Ensure it's below the navbar but above other content */
}

.navbar-search {
  max-width: 300px;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 0.9rem;
  transition: box-shadow 0.2s ease;
  background-color: #fff;
}

.navbar-search:focus {
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
  border-color: #3498db;
}

/*******************************************
  NETWORK SELECTOR
********************************************/
.network-selector {
  display: flex;
  align-items: center;
  background-color: var(--clr-secondary);
  border-radius: 4px;
  padding: 0.25rem;
  gap: 0.5rem;
}

.network-icon {
  width: 24px;
  height: 24px;
}

.network-selector select {
  background-color: transparent;
  border: none;
  color: var(--clr-white);
  font-size: 0.9rem;
  cursor: pointer;
}

.network-selector select:focus {
  outline: none;
}

/*******************************************
  CIRCULAR NAV ITEMS
********************************************/
.circular-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.circular-nav-item:hover {
  transform: translateY(-3px);
}

.circular-nav-content {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--clr-white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.25rem;
  transition: box-shadow 0.3s ease;
}

.circular-nav-item.active .circular-nav-content {
  box-shadow: 0 0 10px var(--clr-blue);
}

.logo-container {
  padding: 5px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.circular-nav-content:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.circular-nav-content img,
.circular-nav-content svg {
  width: 30px;
  height: 30px;
}

.nav-label {
  font-size: 0.75rem;
  color: var(--clr-white);
  text-align: center;
}

/*******************************************
  TABS & ACCOUNT AREA
********************************************/
.tabs__account {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--clr-secondary);
  border-radius: 10px;
  padding: 0.5rem 0.75rem;
}

.balance-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--clr-secondary);
  border: none;
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.balance-button:hover {
  background-color: var(--clr-blue);
}

.balance-button small {
  font-size: 0.6rem;
  color: var(--clr-white);
  opacity: 0.7;
}

.balance-amount {
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--clr-white);
}

.account-link,
.connect-btn,
.disconnect-btn {
  font-size: 0.75rem;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: var(--clr-blue);
  color: var(--clr-white);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.account-link {
  background-color: var(--clr-primary);
}

.connect-btn,
.disconnect-btn {
  background-color: var(--clr-blue);
  color: var(--clr-white);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.account-link:hover,
.connect-btn:hover,
.disconnect-btn:hover {
  background-color: var(--clr-blue-dark);
}

.identicon {
  border-radius: 50%;
}

/*******************************************
  SCROLL RESETS FOR SMALLER SCREENS
********************************************/
@media (max-width: 768px) {
  .navbar-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
  }

  .navbar-tabs {
    width: 100%;
    justify-content: center;
  }

  .navbar-left, .navbar-right {
    width: 100%;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  .tabs__account {
    margin-top: 0.5rem;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .connect-btn,
  .disconnect-btn,
  .account-link {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .circular-nav-content,
  .logo {
    width: 35px;
    height: 35px;
  }

  .circular-nav-content img,
  .circular-nav-content svg {
    width: 20px;
    height: 20px;
  }

  .nav-label {
    font-size: 0.65rem;
  }

  .balance-button {
    font-size: 0.75rem;
  }

  .balance-amount {
    font-size: 0.75rem;
  }

  .search-row {
    margin-top: 70px; /* ensure enough space for the pinned navbar on small screens */
  }
}
