/* src/components/FeatureContainer.css */

.feature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.locked {
  opacity: 0.7;
  pointer-events: none;
}

.locked-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.lock-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.feature-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.feature-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 15px;
}

.feature-title {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 10px;
  text-align: center;
}

.feature-description {
  font-size: 1rem;
  color: #555;
  text-align: center;
  margin-bottom: 15px;
}

.feature-button {
  position: relative;
  z-index: 2;
}

.feature-button:hover {
  background-color: var(--accent-color);
}
